import React from 'react';

export default function SocialName({ platform: k }) {
  return (
    <span
      className={`text-s${k.toLowerCase()} flex flex-row items-center gap-2 -ml-2`}
    >
      {/*<i*/}
      {/*  className={`icon-social-${k.toLowerCase()} text-md font-bold align-middle mr-2`}*/}
      {/*/>*/}
      <img
        className="w-4 h-4"
        src={`/images/social/color/${k.toLowerCase()}_icon.png`}
      />
      <span>{k}</span>
    </span>
  );
}

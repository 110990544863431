import React from 'react';
import SocialName from '../components/display/SocialName';

import facebook from './facebook';
import instagram from './instagram';
import twitter from './twitter';
import youtube from './youtube';
import linkedin from './linkedin';
import moj from './moj';
import chingari from './chingari';
import sharechat from './sharechat';
import koo from './koo';
import website from './website';

export function allPlatformsNames() {
  const platforms = allPlatforms();
  return Object.keys(platforms).map((p) => platforms[p].name);
}

export function allPlatforms() {
  return {
    instagram: instagram(),
    facebook: facebook(),
    twitter: twitter(),
    youtube: youtube(),
    linkedin: linkedin(),
    moj: moj(),
    chingari: chingari(),
    sharechat: sharechat(),
    koo: koo(),
    website: website(),
  };
}

export function getPlatform(platform) {
  if (platform) {
    return allPlatforms()[platform.toLowerCase()];
  } else {
    return [];
  }
}

export function getSocialContents(platform) {
  const posts = getSocialContentNames(platform);
  return Object.keys(posts);
}

export function getSocialContentNames(platform) {
  try {
    return getPlatform(platform).posts();
  } catch (e) {
    return [];
  }
}

export function getProfileUrl(platform) {
  return getPlatform(platform).profileUrl;
}

export function displayNameWithIcon(platform) {
  return <SocialName platform={platform} />;
}

export function amountCalc(platform, no_of_followers) {
  const x = budgetCalc(platform, no_of_followers, no_of_followers, 'simple');
  const y = budgetCalc(platform, no_of_followers, no_of_followers, 'complex');

  const y2 = y[1];
  const y1 = y[0];
  const x2 = x[1];
  const x1 = x[0];
  return (y2 / x1) * (no_of_followers - x1) + y1;
}

/**
 *
 * @param platform
 * @param no_of_followers
 */
export function priceCalc(platform, no_of_followers) {
  return [
    budgetCalc(platform, no_of_followers, no_of_followers, 'simple')[0],
    budgetCalc(platform, no_of_followers, no_of_followers, 'complex')[1],
  ];
}

/**
 *
 * @param platform
 * @param no_of_friends_min
 * @param no_of_friends_max
 * @param type any of [simple, complex]
 */
export function budgetCalc(
  platform,
  no_of_friends_min,
  no_of_friends_max,
  type
) {
  const min = oneBudget(platform, no_of_friends_min, type);
  const max = oneBudget(platform, no_of_friends_max, type);

  return [min, max];
}

export function oneBudget(platform, no_of_friends, type) {
  const BUDGET = getPlatform(platform).BUDGET;
  const MULTIPLIER = getPlatform(platform).MULTIPLIER;
  const v = BUDGET.map((b) => {
    if (b.friends[0] <= no_of_friends && no_of_friends <= b.friends[1]) {
      const num =
        b[type][0] +
        ((no_of_friends - b.friends[0]) / (b.friends[1] - b.friends[0])) *
          (b[type][1] - b[type][0]);

      const roundNum = Math.round(num / 100) * 100;
      return roundNum * MULTIPLIER;
    }
  });

  const filtered = v.filter((f) => f);
  return filtered && filtered.length > 0 ? filtered[0] : [0, 0];
}

export default function chingari() {
  return {
    name: 'Chingari',
    posts: posts,
    profileUrl: 'https://chingari.io/XXXXXXXXX',
    postUrl: 'https://chingari.io/share/post?id=XXXXXXXXX',
    BUDGET: BUDGET,
    MULTIPLIER: 1.2,
  };
}

export function posts() {
  return {
    video_post: 'Video',
  };
}

export const BUDGET = [
  { friends: [0, 1000], simple: [100, 200], complex: [150, 350] },
  { friends: [1000, 2500], simple: [200, 350], complex: [350, 500] },
  { friends: [2500, 5000], simple: [350, 500], complex: [500, 700] },
  { friends: [5000, 10000], simple: [500, 1500], complex: [700, 2500] },
  { friends: [10000, 20000], simple: [1500, 4000], complex: [2500, 6000] },
  { friends: [20000, 50000], simple: [4000, 12000], complex: [6000, 15000] },
  { friends: [50000, 100000], simple: [12000, 25000], complex: [15000, 35000] },
  {
    friends: [100000, 250000],
    simple: [25000, 50000],
    complex: [35000, 70000],
  },
  {
    friends: [250000, 500000],
    simple: [50000, 90000],
    complex: [70000, 150000],
  },
  {
    friends: [500000, 1000000],
    simple: [100000, 'above'],
    complex: [150000, 'above'],
  },
];

import {
  faBell,
  faBriefcase,
  faBullhorn,
  faChartLine,
  faPlusSquare,
  faQuestionCircle,
  faSearch,
  faTag,
  faTags,
  faThLarge,
  faUser,
  faUsers,
  faUserTie,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
// import { v4 as uuidv4 } from 'uuid';
import allCityState from '../json/india_city_state.json';
import topCities_ from '../json/top_india_city_state.json';
import topStates_ from '../json/top_india_state.json';
import topDeliveryProviders_ from '../json/deliveryProviders.json';
import React from 'react';
import { getSocialContentNames, getSocialContents } from '../platforms';
import {
  fiBell,
  fiBrand,
  fiCampaigns, fiComment,
  fiCreateCampaign,
  fiHelp,
  fiOverview,
  fiPeople,
  fiProfile,
  fiSearch,
  fiSocialAccounts,
  fiWallet,
} from '../components/fluenticons';
import {faComments} from "@fortawesome/free-regular-svg-icons";

// export const uuid = uuidv4;

const STATES = {};
const CITIES = {};

allCityState.map((cs) => {
  if (cs.type === 'State') {
    STATES[cs.name] = cs;
  } else if (cs.type === 'City' || cs.type === 'Region') {
    CITIES[cs.name] = cs;
  }
});

export function stateForCity(city) {
  return CITIES[city] ? CITIES[city].state : city;
}

export function isServer() {
  return true;
  //return false";
}

export function colors() {
  return [
    '#5470C6',
    '#91CC75',
    '#FAC858',
    '#EE6766',
    '#73C0DE',
    '#3AA272',
    '#FC8453',
    '#9A60B4',
    '#EA7CCC',
    '#FFB200',
    '#990000',
    '#7C3E66',
    '#F24C4C',
    '#187498',
    '#205375',
    '#446A46',
    '#A85CF9',
    '#066163',
    '#82A284',
    '#FFC4DD',
    '#CE9461',
    '#4D4C7D',
    '#E78EA9',
    '#B20600',
    '#4700D8',
    '#F0A500',
  ];
}

export function andMore(data = [], showMin = 3) {
  if (data.length <= showMin) {
    return data.join(', ');
  } else {
    return (
      data.slice(0, showMin).join(', ') +
      ' +' +
      (data.length - showMin) +
      ' more'
    );
  }
}

export function isProd() {
  return ['dev.infloso.com'].indexOf(location.hostname) === -1;
}

export function cdnDomain(path) {
  const isprod = isProd();

  if (path) {
    if (path.startsWith('http')) {
      return path;
    }
    if (!path.startsWith('/')) {
      path = '/' + path;
    }
    return isprod
      ? 'https://cdnc.infloso.com' + path
      : 'https://dev-cdn.infloso.in/cdn' + path;
  }

  return isprod
    ? 'https://cdnc.infloso.com/'
    : 'https://dev-cdn.infloso.in/cdn/';
}

export function enableCDN(url) {
  // remove domain
  if (url) {
    if (url.startsWith('/')) {
      return url;
    } else {
      url = url
        .replace('https://infloso-pre-prod-storage.s3.amazonaws.com/', '/')
        .replace('https://infloso-dev.s3.amazonaws.com/', '/')
        .split('?')[0];

      // if (!url.startsWith('/cdn/') && !url.startsWith('http')) {
      //   url = '/cdn' + url;
      // }

      if (url.startsWith('/')) {
        //add cdn domain
        return cdnDomain(url);
      }
    }
  }

  return url;
}

export function round2(num) {
  return Math.round(num * 100) / 100;
}

export function isLoading(module, field) {
  return module.loading && module.loading.indexOf(field) > -1;
}

export function isDirty(originalObject, newObject) {
  return JSON.stringify(originalObject) !== JSON.stringify(newObject);
}

export const clientStorage = {
  get: (key) => {
    return ls.getItem(key);
  },
  set: (key, value) => {
    return ls.setItem(key, value);
  },
};

export const ls =
  typeof localStorage !== 'undefined'
    ? localStorage
    : {
        getItem: () => {},
        setItem: () => {},
        removeItem: () => {},
      };

// export function ls() {
//   if (typeof localStorage !== 'undefined') {
//     return localStorage;
//   }else{
//     return {getItem:()=>{},setItem:()=>{},removeItem:()=>{} }
//   }
// }

export function throttle(callback, limit) {
  let waiting = false; // Initially, we're not waiting
  return function () {
    // We return a throttled function
    if (!waiting) {
      // If we're not waiting
      callback.apply(this, arguments); // Execute users function
      waiting = true; // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        waiting = false; // And allow future invocations
      }, limit);
    }
  };
}

export function getMark(action) {
  const mark = action.mark;
  let markArr = [];

  if (!mark) {
    markArr = [];
  } else if (Array.isArray(mark)) {
    markArr = mark;
  } else if (typeof mark === 'object') {
    markArr = Object.keys(mark);
  } else if (typeof mark === 'string') {
    markArr = [mark];
  }

  return markArr;
}

export function markLoading(action, state) {
  const mark = getMark(action);

  const loading = [...state.loading];
  const errors = { ...state.errors };
  const success = { ...state.success };

  if (mark && mark.length > 0) {
    mark.map((m) => {
      if (loading.indexOf(m) === -1) {
        loading.push(m);
        delete errors[m];
        delete success[m];
      }
    });
  }

  return { loading, errors, success };
}

export function markFailure(action, state) {
  const mark = getMark(action);

  const loading = [...state.loading];
  const errors = { ...state.errors };
  const success = { ...state.success };

  if (mark && mark.length > 0) {
    mark.map((m) => {
      if (loading.indexOf(m) > -1) {
        //Remove from loading
        loading.splice(loading.indexOf(m), 1);

        if (action.result && action.result[m] && action.result[m].length > 0) {
          errors[m] = action.result[m][0];
        } else if (
          action.result &&
          action.result.message &&
          action.result.message[m] &&
          action.result.message[m].length > 0
        ) {
          errors[m] = action.result.message[m][0];
        } else if (
          action.result &&
          action.result.message &&
          action.result.message.length > 0
        ) {
          errors[m] = action.result.message;
        } else {
          errors[m] = 'Could not save';
        }
        delete success[m];
      }
    });
  }
  return { loading, errors, success };
}

export function markSuccess(action, state) {
  const mark = getMark(action);

  const loading = [...state.loading];
  const errors = { ...state.errors };
  const success = { ...state.success };

  if (mark && mark.length > 0) {
    mark.map((m) => {
      if (loading.indexOf(m) > -1) {
        //Remove from loading
        loading.splice(loading.indexOf(m), 1);
      }
      success[m] = 'Saved Successfully';
      delete errors[m];
    });
  }
  return { loading, errors, success };
}

export function isDesktop() {
  return typeof window !== 'undefined' && window.innerWidth >= 1024;
}

export function isTablet() {
  return (
    typeof window !== 'undefined' &&
    window.innerWidth < 1024 &&
    window.innerWidth > 400
  );
}

export function isMobile() {
  return typeof window !== 'undefined' && window.innerWidth < 1024;
}

export function runPromiseSerial(tasks) {
  var result = Promise.resolve();
  tasks.forEach((task) => {
    result = result.then(() => task());
  });
  return result;
}

export function icons() {
  return {
    Home: faThLarge,
    Search: faSearch,
    file: faBullhorn, //campaign
    'add-file': faPlusSquare,
    tag: faTag,
    Tags: faTags,
    Bell: faBell,
    User: faUser,
    Users: faUserTie,
    ManyUsers: faUsers,
    ChartLine: faChartLine,
    wallet: faWallet,
    help: faQuestionCircle,
    chat: faComments,
    Briefcase: faBriefcase,
  };
}

export function fiIcons() {
  return {
    Home: fiOverview,
    file: fiCampaigns, //campaign
    'add-file': fiCreateCampaign,
    User: fiProfile,
    wallet: fiWallet,
    help: fiHelp,

    SocialAccount: fiSocialAccounts,
    Search: fiSearch,
    tag: fiBrand,
    Tags: fiBrand,
    Bell: fiBell,
    Users: fiPeople,
    ManyUsers: fiPeople,
    ChartLine: fiHelp,
    chat: fiComment,
    Briefcase: fiWallet,
  };
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function intersectArray(smallerArr1, largerArr2) {
  return smallerArr1.filter((a) => largerArr2.indexOf(a) > -1);
}

export function diffArray(smallerArr1, largerArr2) {
  return largerArr2.filter((a) => smallerArr1.indexOf(a) === -1);
}

export function getCampaignModesPlatforms(campaign) {
  return getCampaignModes(campaign).map((m) => m.mode_name);
}

export function getCampaignModes(campaign) {
  return (campaign && campaign.campaignmodes) || [];
}

export function getDeliverablesForPlatform(application, platform) {
  return (application.deliverables || [])
    .map((d) => {
      if (d.startsWith(platform)) {
        return d
          .replace(platform + '_', '')
          .split(' ')
          .slice(1)
          .join(' ');
      } else {
        return null;
      }
    })
    .filter((d) => d);
}

export function getDeliverablesWithCountForPlatform(application, platform) {
  return (application.deliverables || [])
    .map((d) => {
      if (d.startsWith(platform)) {
        return d.replace(platform + '_', '');
      } else {
        return null;
      }
    })
    .filter((d) => d);
}

export function getCampaignModeForPlatform(campaign, platform) {
  return getCampaignModes(campaign).filter((c) => c.mode_name === platform)[0];
}

export function getCampaignApplicationById(campaign, id) {
  return (
    (campaign &&
      campaign.applications &&
      campaign.applications.length > 0 &&
      campaign.applications.filter((a) => a.id === id)[0]) ||
    {}
  );
}

export function getCampaignApplicationByPlatform(campaign, platform) {
  return (
    (campaign &&
      campaign.applications &&
      campaign.applications.length > 0 &&
      campaign.applications.filter(
        (a) => getPlatformFromApplication(a) === platform
      )[0]) ||
    {}
  );
}

export function getCampaignApplication(campaign, index = 0) {
  return (
    (campaign &&
      campaign.applications &&
      campaign.applications.length > 0 &&
      campaign.applications[index]) ||
    {}
  );
}

export function getPlatformFromApplication(a) {
  return (
    a &&
    a.social_media &&
    a.social_media.length > 0 &&
    a.social_media[0].social_media_platform
  );
}

export function getCampaignLinks(application) {
  return getCampaignX(application, 'links');
}

export function getCampaignMediaByPlatformAndType(
  campaign,
  platform,
  media_type
) {
  let media =
    campaign && campaign.media && campaign.media.length > 0
      ? campaign.media
      : [];

  if (platform) {
    media = media.filter((m) => m.platform === platform);
  }
  if (media_type) {
    media = media.filter((m) => m.media_type === media_type);
  }

  return media;
}

export function getCampaignMedia(application) {
  return getCampaignX(application, 'media');
}

export function getCampaignX(application, X = 'media') {
  return application[X] || [];
}

export function getMediaByPlatform(application, X) {
  const media = getCampaignX(application, X);

  const media_by_platform = {};

  (Array.isArray(media) && media ? media : []).map((m) => {
    const platform = m.platform || m.social_media_name;
    if (!media_by_platform[platform]) {
      media_by_platform[platform] = {};
    }

    if (!media_by_platform[platform][m.media_type]) {
      media_by_platform[platform][m.media_type] = [];
    }
    media_by_platform[platform][m.media_type].push(m);
  });

  return media_by_platform;
}

export function getCampaignLinksObject(application, type, platform) {
  return getCampaignXObject(application, type, platform, 'links');
}

export function getCampaignInsightsObject(application, type, platform) {
  return getCampaignXObject(application, type, platform, 'insights');
}

export function getCampaignMediaObject(application, type, platform) {
  return getCampaignXObject(application, type, platform, 'media');
}

export function getCampaignXObject(application, type, platform, X) {
  const NAMES = getSocialContentNames(platform);
  const media_by_platform = getMediaByPlatform(application, X);
  return (
    media_by_platform &&
    media_by_platform[platform] &&
    media_by_platform[platform][NAMES[type]] &&
    media_by_platform[platform][NAMES[type]]
  );
}

export function campaignConnectedAllPlatforms(platforms, user) {
  return platforms.map((p) => campaignConnectedPlatforms(p.user));
}

export function campaignConnectedPlatforms(mode_name, user) {
  let available = [];
  if (user && user.social_profiles && user.social_profiles.length > 0) {
    available = (user.social_profiles || []).filter(
      (s) => s.social_media_platform === mode_name
    );
  }

  return available;
}

export function getDeliverables(platform) {
  return getSocialContents(platform);
}

export function getCampaignSocialMedia(application) {
  return application.social_media && application.social_media.length > 0
    ? application.social_media
    : [];
}

export function getCampaignSocialMediaPlatforms(application) {
  return getCampaignSocialMedia(application).map(
    (s) => s.social_media_platform
  );
}

// export function getPlatforms() {
//   return ['Facebook', 'Instagram', 'YouTube'];
// }

// export function getSocialContents(platform) {
//   const contents = {
//     Facebook: ['static_post', 'video_post', 'story_post'],
//     Instagram: [
//       'static_post',
//       'video_post',
//       'story_post',
//       'insta_live',
//       'highlights',
//       'igtv',
//       'swipe_up',
//       'reels',
//     ],
//     YouTube: ['video_post', 'youtube_live'],
//   };
//   return contents[platform];
// }
//
// export function getSocialContentNames(platform) {
//   return {
//     static_post: 'Post',
//     video_post: platform === 'YouTube' ? 'Video' : 'Video',
//     story_post: 'Story Post',
//     insta_live: 'Insta Live',
//     highlights: 'Highlights',
//     igtv: 'IGTV',
//     swipe_up: 'Swipe Up',
//     reels: 'Reels',
//     youtube_live: 'YouTube Live',
//   };
// }

export function formatLocationSelect() {
  // eslint-disable-next-line react/display-name
  return (data) => {
    return (
      <div className="flex flex-row">
        <span className="flex-1">{data.label}</span>
        <span className="flex-initial rs_val text-gray-600 text-xs border-1 border-gray-300 bg-gray-300 rounded-lg px-2">
          {data.type}
        </span>
      </div>
    );
  };
}

export function topCities() {
  return topCities_
    .filter((v) => v.type === 'City')
    .map((v) =>
      v.name
        ? { label: v.name, value: v.name, ...v }
        : { label: v.city, value: v.city }
    );
  // return allCityState.filter(v=>v.type === "City").map(v=>(v.name ? {label:v.name,value:v.name, ...v} : {label:v.city,value:v.city}));
  // return topCities_.map(v=>({label:v.city,value:v.city}))
}

export function topStates() {
  return topStates_.map((v) =>
    v.name
      ? { label: v.name, value: v.name, ...v }
      : { label: v.city, value: v.city }
  );
}

export function topDeliveryProviders() {
  return topDeliveryProviders_.map((v) =>
    v.name
      ? { label: v.name, value: v.name, ...v }
      : { label: v.city, value: v.city }
  );
}

export function containsUppercase(text) {
  return text.match(/[A-Z]+/) != null;
}

export function containsLowercase(text) {
  return text.match(/[a-z]+/) != null;
}

export function containsNumber(text) {
  return text.match(/[0-9]+/) != null;
}

export function containsSpecialChar(text) {
  return text.match(/[!@#\$%\^\&*\)\(+=._-]+/) != null;
}

export function has8Char(text) {
  return text.length >= 8;
}

export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function firstCharUpper(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export function loadScript(src, onLoad) {
  const script = document.createElement('script');
  script.onload = onLoad || (() => {});
  script.src = src;
  document.head.appendChild(script);
}

export function isUrl(value) {
  const url =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  return !!(value && value.match && value.trim().match(url));
}

export function isNumber(number) {
  try {
    const num = parseInt(number + '', 10);
    // if(num === NaN)
    return !!num;
  } catch (e) {
    return false;
  }
}

export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}

export function nToBr(text) {
  return text.replaceAll('\n', '<br/>');
}

export function calcInfluencerAmount(application, campaign) {
  const isBarter = campaign.campaign_collaboration_type === 'Barter';

  let amount = calcInfluencerModeAmount(application, campaign);
  if (application.quote_amount > 0) {
    amount = Math.round(application.quote_amount * 1.1);
  }
  if (application.brand_counter_offer > 0) {
    amount = application.brand_counter_offer;
  }
  if (application.influencer_counter_offer > 0) {
    amount = Math.round(application.influencer_counter_offer * 1.1);
  }
  if (isBarter) {
    amount = 0;
  }
  if (application.is_barter_campaign) {
    amount = 0;
  }
  return amount;
}

export function calcInfluencerModeAmount(application, campaign) {
  try {
    const platform = application.social_media[0].social_media_platform;
    return campaign.campaignmodes.filter((m) => m.mode_name === platform)[0]
      .budget_for_influencer;
  } catch (e) {
    return 0;
  }
}

export function getMediaType(filename) {
  if (
    filename.toLowerCase().endsWith('.jpg') ||
    filename.toLowerCase().endsWith('.jpeg') ||
    filename.toLowerCase().endsWith('.png')
  ) {
    return 'Image';
  }
  if (
    filename.toLowerCase().endsWith('.mov') ||
    filename.toLowerCase().endsWith('.mp4')
  ) {
    return 'Video';
  }
  return null;
}

export function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return '0 Bytes';
  let k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const DEFAULT_CAMPAIGN_BUDGET = 1000;

export const DEFAULT_PRICING = 1000;
export const BARTER_PRICING = 1000;
export const PAID_PRICING = 1000;
export const VISITS_PRICING = 5000;
export const ACTIONS_BARTER_PRICING = 100;
export const ACTIONS_PAID_PRICING = 200;

export const ACCOUNT_MANAGER_PRICING = 300;
export const ACCOUNT_MANAGER_PRICING_DISCOUNT = 200;

export function calcBudgetPerInfluencer(campaign, campaign_mode, user) {
  let budget = DEFAULT_PRICING;
  if (campaign.campaign_collaboration_type === 'Barter') {
    // console.log('calcBudgetPerInfluencer', 'isBarter');
    if (user.barter_cost) {
      // console.log('calcBudgetPerInfluencer', 'isBarterCost', user.barter_cost);
      budget = user.barter_cost;
    } else {
      budget = BARTER_PRICING;
    }
  }

  if (campaign.type === 'Visits' || campaign.collaboration_type === 'Visits') {
    // console.log('calcBudgetPerInfluencer', 'isVisits');
    budget = VISITS_PRICING;
  }

  if (campaign.campaign_collaboration_type === 'Paid') {
    // console.log('calcBudgetPerInfluencer', 'isPaid');
    budget = PAID_PRICING;
    if (campaign_mode && campaign_mode.budget_per_influencer) {
      // console.log(
      //   'calcBudgetPerInfluencer',
      //   'isbudget_per_influencer',
      //   campaign_mode.budget_per_influencer
      // );
      budget = campaign_mode.budget_per_influencer;
    }
  }

  return budget;
}

export function calcCampaignBudget(campaign, user) {
  const GST = 18;
  let totalBudget = 0;
  let totalBudgetWithGST = 0;
  let budgetBreakdown = calcCampaignBudgetBreakdown(campaign, user);
  // console.log('campaign.amount_after_discount', campaign.amount_after_discount);
  totalBudget = campaign.amount_after_discount;

  if (!totalBudget) {
    totalBudget = DEFAULT_CAMPAIGN_BUDGET;
  }

  //Apply Discount if applicable
  // if (
  //   campaign.campaign_collaboration_type !== 'Paid' &&
  //   campaign.campaign_tier === 'Discount' &&
  //   campaign.discount
  // ) {
  //   // console.log('calcBudgetPerInfluencer', 'idDiscount', campaign.discount);
  //   totalBudget = totalBudget - (totalBudget * campaign.discount) / 100;
  // }

  // console.log('budget breakdown', budgetBreakdown);
  totalBudgetWithGST = totalBudget + (totalBudget * GST) / 100;

  return [totalBudget, GST, totalBudgetWithGST];
}

export function calcCampaignBudgetBreakdown(campaign, user) {
  // console.log('calcCampaignBudgetBreakdown', campaign);
  let budgetBreakdown = [];
  budgetBreakdown = campaign.campaignmodes
    ? campaign.campaignmodes.map(
        (t) => t.no_of_influencers * calcBudgetPerInfluencer(campaign, t, user)
      )
    : [DEFAULT_PRICING];

  if (
    campaign.campaign_collaboration_type === 'Paid' &&
    campaign.campaign_type === 'Flexible'
  ) {
    budgetBreakdown = [parseInt(campaign.campaign_budget + '')];
  }

  return budgetBreakdown;
}

export function calcAccountManagerCost(campaign, user) {
  // if (campaign.discount && campaign.discount >= 20) {
  //   return ACCOUNT_MANAGER_PRICING_DISCOUNT;
  // }

  if (campaign.collaboration_type === 'Visits') {
    return 0;
  }
  const premium_support = calcSupportPremium(campaign, user);
  if (campaign.campaign_collaboration_type === 'Barter') {
    return ACCOUNT_MANAGER_PRICING + calcSupportPremium(campaign, user);
  } else {
    let budget_per_influencer =
      campaign.campaign_budget / campaign.no_of_influencers;
    if (campaign.support) {
      budget_per_influencer = budget_per_influencer - premium_support;

      budget_per_influencer =
        (budget_per_influencer / (100 + user.paid_support_percentage)) * 100;
      //
      //
      //
      // return (
      //   (((campaign.campaign_budget * 100) /
      //     (100 + user.paid_support_percentage) /
      //     campaign.no_of_influencers) *
      //     user.paid_support_percentage) /
      //   100
      // );
    }

    return (
      (budget_per_influencer * user.paid_support_percentage) / 100 +
      premium_support
    );
  }
}

export function calcSupportPremium(campaign, user) {
  return user.premium_on_support || 0;
}

export function calcPremium(campaign, user) {
  if (campaign.campaign_collaboration_type === 'Barter') {
    if (
      campaign.collaboration_type === 'Buy Products' &&
      user.premium_on_buy_product
    ) {
      return user.premium_on_buy_product;
    } else if (
      campaign.collaboration_type === 'Send Product' &&
      user.premium_on_send_product
    ) {
      return user.premium_on_send_product;
    } else if (
      campaign.collaboration_type === 'Digital Products' &&
      user.premium_on_digital_product
    ) {
      return user.premium_on_digital_product;
    } else if (
      campaign.collaboration_type === 'Visits' &&
      user.premium_on_visits
    ) {
      return user.premium_on_visits;
    } else if (
      campaign.collaboration_type === 'Actions' &&
      user.premium_on_actions
    ) {
      return user.premium_on_actions;
    }
  }
  return 0;
}

export default function twitter() {
  return {
    name: 'Twitter',
    posts: posts,
    postUrl: 'https://twitter.com/XXXXXXXXX/status/YYYYYYYYY',
    BUDGET: BUDGET,
    MULTIPLIER: 1,
  };
}

export function posts() {
  return {
    static_post: 'Tweet',
  };
}

export const BUDGET = [
  { friends: [0, 1000], simple: [100, 200], complex: [150, 350] },
  { friends: [1000, 2500], simple: [200, 350], complex: [350, 500] },
  { friends: [2500, 5000], simple: [350, 500], complex: [500, 700] },
  { friends: [5000, 10000], simple: [500, 1500], complex: [700, 2500] },
  { friends: [10000, 20000], simple: [1500, 4000], complex: [2500, 6000] },
  { friends: [20000, 50000], simple: [4000, 12000], complex: [6000, 15000] },
  { friends: [50000, 100000], simple: [12000, 25000], complex: [15000, 35000] },
  {
    friends: [100000, 250000],
    simple: [25000, 50000],
    complex: [35000, 70000],
  },
  {
    friends: [250000, 500000],
    simple: [50000, 90000],
    complex: [70000, 150000],
  },
  {
    friends: [500000, 1000000],
    simple: [100000, 250000],
    complex: [150000, 350000],
  },
  {
    friends: [1000000, 1000000000],
    simple: [250000, 25000000],
    complex: [350000, 35000000],
  },
];
